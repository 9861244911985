@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"






















.rooms-page
  .sections-elem
    margin-bottom: m(4)
  .room
    >.row
      .box
        >.row
          flex-direction: row-reverse
          .image
            //margin-top: m(7)
    &.has-1
      .image
        flex-basis: 50%
      .text
        flex-basis: 50%
